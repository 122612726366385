import {
  useDraftComputeNodeTypeQuery,
  usePublishedComputeNodeTypeQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  type DraftComputeNodeTypeQuery,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import {
  useComputeNodesVars,
  useDataRoom,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";

type ComputeNodeTypeHookResult =
  | Extract<
      NonNullable<DraftComputeNodeTypeQuery>["draftNode"],
      { computationType: any }
    >
  | undefined;

export const WORKER_TYPE_LABEL: Record<WorkerTypeShortName, string> = {
  [WorkerTypeShortName.Post]: "Post",
  [WorkerTypeShortName.Preview]: "Airlock",
  [WorkerTypeShortName.S3]: "S3",
  [WorkerTypeShortName.Python]: "Python",
  [WorkerTypeShortName.R]: "R",
  [WorkerTypeShortName.Sql]: "Native-SQL",
  [WorkerTypeShortName.Sqlite]: "SQL",
  [WorkerTypeShortName.Match]: "Matching",
  [WorkerTypeShortName.Synthetic]: "Synthetic",
};

const useComputeNodeType = (
  computeNodeId: string
): ComputeNodeTypeHookResult => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { commitId } = useRequest();
  const { executionContext } = useComputeNodesVars();
  const shouldUseDraft =
    !isPublished ||
    executionContext === "development" ||
    executionContext === "requests";
  // draft & development
  const { data: draftData } = useDraftComputeNodeTypeQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !shouldUseDraft,
    variables: { computeNodeId },
  });
  // published
  const { data: publishedData } = usePublishedComputeNodeTypeQuery({
    fetchPolicy: "cache-only",
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "The computation could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: shouldUseDraft,
    variables: { commitId, computeNodeId, dcrHash, driverAttestationHash },
  });

  return (
    (draftData?.draftNode as ComputeNodeTypeHookResult) ||
    (publishedData?.publishedNode as ComputeNodeTypeHookResult)
  );
};

export default useComputeNodeType;
